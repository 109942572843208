<template>
  <UpsellMenuItemEdit
    leaveRoute="upsell-menu-item"
    editRoute="upsell-menu-item-edit"
    selectMoreItemsText="Select Upsell Items"
    selectMoreExclusionaryItemsText="Select Items to Exclude from Upsell"
    emptyText="There does not appear to be any upsell items, please click below to add some."
    emptyExclusionaryText="There does not appear to be any excluded upsell items, please click below to add some."
    :allUpsellAddonItems="currentItems"
    :upsellAddonItem="upsellItem"
    :title="title"
    :saveMethod="save"
    :deleteMethod="deleteItem"
  />
</template>

<script>
import UpsellMenuItemEdit from "components/upsell/upselladdon_menu_item_edit.vue";
import { UpsellDataHelper } from "mixins/upsell.js";
import { mapActions, mapGetters } from "vuex";

export default {
    mixins: [UpsellDataHelper],
    components: {
        UpsellMenuItemEdit,
    },
    computed: {
        title() {
            let item = this.upsellItem;
            if (item === undefined) return "";
            if (this.exclusionary) return `Excluded Upsell Items for ${item.display}`;
            return `Upsell Items for ${item.display}`;
        },
        upsellItem() {
            let upsellItem = this.currentItems.find(
                (el) =>
                    el.TargetID == `${this.$route.params.TargetID}`.replace("e", "") &&
          el.exclusionary == this.exclusionary
            );

            if (upsellItem === undefined) {
                return undefined;
            }

            return upsellItem;
        },
        exclusionary() {
            return `${this.$route.params.TargetID}`.includes("e");
        },
    },
    methods: {
        save(dirtyUpsellPositions) {
            let waypointID = this.$route.params.store;

            return this.updateUpsellItem({
                waypointID,
                item: {
                    ...this.upsellItem,
                    positions: dirtyUpsellPositions,
                },
            });
        },
        deleteItem() {
            let waypointID = this.$route.params.store;
            return this.deleteUpsellItem({
                waypointID,
                targetID: this.upsellItem.TargetID,
                exclusionary: this.exclusionary,
            }).then(() => {});
        },
        ...mapActions("UpsellStore", ["deleteUpsellItem", "updateUpsellItem"]),
    },
};
</script>

<style>
</style>
